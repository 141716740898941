import $ from 'jquery';


function accordion () {


    $('.js-accordion').click(function(e) {
        e.preventDefault();

        var $this = $(this);
        if ($this.hasClass('active')) {
            $this.removeClass('active');
        }else{
            $this.parent().parent().find('.c-accordion__item .js-accordion').removeClass('active');
            $this.toggleClass('active');
        }
        if ($this.next().hasClass('active')) {
            $this.next().removeClass('active');
            $this.next().slideUp(350);
        } else {
            $this.parent().parent().find('.c-accordion__item .c-accordion__content').removeClass('active');
            $this.parent().parent().find('.c-accordion__item .c-accordion__content').slideUp(350);
            $this.next().toggleClass('active');
            $this.next().slideToggle(350);
        }
    });

};

export  {accordion};