
import $ from 'jquery';
import slick from 'slick-carousel';
function nav () {
    $('.js-toggle-button').on('click', function (e) {
        e.preventDefault();
        $('.js-toggle-nav').slideToggle('fast', 'swing');
    });
    $('.js-subnav').on('click', function(e){
        e.preventDefault();
        var targetnav=$(this).attr('data-rel');
        $("div").find("[data-subnav='" + targetnav + "']").slideToggle();

    });
    $('.js-search').on('click',function () {
        $('.js-searchblock').fadeToggle();
    });

    $(window).scroll(function(){
        var scroll = $(window).scrollTop();
        if(scroll>150){
            if(!$('.c-header').hasClass('stuck')){
                $('.c-header').addClass('stuck');
            }
        }else if(scroll<100){
            $('.c-header').removeClass('stuck');
        }
    });
};



export  {nav};