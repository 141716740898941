
import $ from 'jquery';
require('../vendor/jquery.easyembed.min.js');

function videos (){

    $('.js-video').easyEmbed();


}

export  {videos};