
import $ from 'jquery';

function toggle (){

    $('.js-toggle').on('click',function(e){
        e.preventDefault();
        $(this).parent().find('.js-toggleBlock').slideToggle();
    });
    $('.js-booking').on('click',function(e){
        e.preventDefault();
        $('.js-booking-box').slideToggle();
        $('.js-booking-box').toggleClass('scrolled');
        if($('.js-booking-box').hasClass('scrolled')){
            $("html, body").animate({scrollTop: $('.js-booking-box').offset().top -150 }, 1000);
        }


    });
}

export  {toggle};