import $ from 'jquery';
import slick from 'slick-carousel';
function modal () {
    $('.js-modals').on('click', function (e) {
        e.preventDefault();
        var target=$(this).attr('data-modal-target');
        // $('.js-favs').animate({width:'toggle'},480);
        $('[data-modal="'+target+'"]').toggleClass('is-active');
        var widthbar = window.innerWidth - document.documentElement.clientWidth;


        /*$('[data-modal="'+target+'"] .js-modal-slider').slick({
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 4000,
            infinite:true,
            prevArrow: $('[data-modal="'+target+'"] .prev-slide'),
            nextArrow: $('[data-modal="'+target+'"] .next-slide'),
            appendDots: $('[data-modal="'+target+'"] .js-slider-count'),
            customPaging: function (slider, i) {
                //FYI just have a look at the object to find available information
                //press f12 to access the console in most browsers
                //you could also debug or look in the source
                console.log(slider);
                var slideNumber   = (i + 1),
                    totalSlides = slider.slideCount;
                return '<b>'+ slideNumber+ '</b>' + '/' + totalSlides;
            }
        });*/









        $('body').toggleClass('c-nav__open');
        if ($('body').hasClass('c-nav__open')) {
            $('body').css('width', 'calc(100% - ' + widthbar + 'px)');
            $(".c-header").animate({
                opacity: 0,
            }, 400);
        } else {
            $('body').css('width', '100%');
            $(".c-header").animate({
                opacity: 1,
            }, 400);
        }

    });

    $( window ).on( "load", function() {
        $('.js-modal').css("display","grid");

       /* $('.js-modals').trigger('click');*/

    });
}

export  {modal};