

import jQuery from 'jquery';
window.$ =  jQuery;

require('../vendor/jquery.nav.js');
function scrollTo () {
    $(document).ready(function(){
       var headerheight= $('.c-header').outerHeight()+80;
        headerheight= 0 - headerheight;
        $('.js-nav').onePageNav({
            currentClass: 'active',
            scrolldiff: headerheight,
            scrollChange: function($currentListItem) {
                //I get fired when you enter a section and I pass the list item of the section
            }
        });

        $('.js-gotop').on('click',function(){
            $('html,body').animate({ scrollTop: 0 });
            return false;
        })
    });


};

export  {scrollTo};