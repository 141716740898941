
import $ from 'jquery';

function subnav () {
    $('.js-subnav-mobile').on("click",function(){
        $('.js-subnav-nav').slideToggle();
    });
    $('.js-subnav-item').on("click",function(){
        $('.js-subnav-item').removeClass('active');
        $(this).addClass('active');
        $('.js-subnav-section').html( $(this).text() );
        $('.js-subnav-nav').hide();

    });
    $(document).scroll(function(){
        if($('.js-nav-scroll').length){
            $('.js-subnav-section').html($('.js-nav-scroll li.active .js-subnav-item').text());
        }
    })
};



export  {subnav};