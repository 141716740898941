

import jQuery from 'jquery';
import PerfectScrollbar from 'perfect-scrollbar';
window.$ =  jQuery;

function scrollBar () {
    const ps = new PerfectScrollbar('.js-scrollbar');

    /* $('.js-eventsCalendar').fullCalendar({
         defaultView: 'month',
         header: {
             left:   '',
             center: 'prev,title,next',
             right:  ''
         }
     });*/


};

export  {scrollBar};