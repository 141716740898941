
require("imports-loader?$=jquery!static/fullcalendar.min.js");
require("imports-loader?$=jquery!static/moment.js");



import {mainSlider,servicesSlider,reviewSlider,serviceSlider,aboutSlider,exhibitionsSlider,eventsSlider,yearsSelector,getYearSelectorSettings,eventSlider,artistSlider,modalSlider} from 'modules/slider';
import {carousel} from './modules/carousel';
import {dateselector} from './modules/datepicker';
import {toggleSearch} from './modules/search';
import {nav} from './modules/nav';
import {scrollTo} from './modules/scrollto';
/*import {popups} from './modules/popups';*/
import {toggle} from './modules/toggle';
import {tabs} from './modules/tabs';
import {accordion} from './modules/accordion';
import {filters} from './modules/filters';
import {videos} from './modules/videos';
import {scrollBar} from './modules/scrollbar';
import {modal} from './modules/modal';
import {subnav} from './modules/subnav';


getYearSelectorSettings();
mainSlider();
dateselector();
toggleSearch();
servicesSlider();
reviewSlider();
nav();
carousel();
scrollTo();
/*popups();*/
toggle();
tabs();
serviceSlider();
aboutSlider();
accordion();
exhibitionsSlider();
eventsSlider();
yearsSelector();
filters();
eventSlider();
scrollBar();
artistSlider();
modal();
modalSlider();
subnav();


videos();
