
import $ from 'jquery';
import slick from 'slick-carousel';
import {getYearSelectorSettings} from './slider';

function tabs (){

    $('.js-selector a').on('click',function(e){
        e.preventDefault();
        if($(this).hasClass('has-slick')){
            var slickTarget=$(this).attr('data-slick');
            $('.' + slickTarget).slick('unslick');
            $('.' + slickTarget).slick(getYearSelectorSettings());

        }
        if($('.js-slider').length){
            $('.js-slider').each(function() {
                $(this).slick('unslick');
                $(this).slick({
                    dots: false,
                    prevArrow: $('.c-slider__arrows-inside .prev-slide', this ),
                    nextArrow: $('.c-slider__arrows-inside .next-slide', this ),

                });
            })
        }
        var targetnav=$(this).attr('data-target');
        $(this).parents('.js-tab-container').find('.c-tabs__item').hide().removeClass('active');
        $(this).parents('.js-tab-container').find('.js-selector a').removeClass('active');
        $(this).addClass('active');
        $(this).parents('.js-tab-container').find("[data-tab='" + targetnav + "']").fadeIn().addClass('active');
        console.log($(this).attr('data-scrolltop'));
        if ($(this).attr('data-scrolltop')) {
            window.scrollTo(0, 0);
        }



    })
}

export  {tabs};