
import $ from 'jquery';

function filters (){

    $(".js-filter-select").on('click','[data-filter]',function(e){
        e.preventDefault();
        var filterObject= $(this).attr('data-filter');
        var filterContainer= $(this).parents('.js-slider-years').attr('data-filter-target');
        $('[data-filter-target="'+ filterContainer +'"] [data-filter]').removeClass('active');
        $(this).addClass('active');
        if(filterObject=="all"){
            $('[data-filter-content="'+ filterContainer +'"] [data-filter-object]').fadeIn();
        }else{
            $('[data-filter-content="'+ filterContainer +'"] [data-filter-object]').hide();
            $('[data-filter-content="'+ filterContainer +'"] [data-filter-object="'+ filterObject +'"]').fadeIn();
        }
    })
}

export  {filters};