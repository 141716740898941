
import $ from 'jquery';
import datetimepicker from 'jquery-datetimepicker';

function dateselector (){
    $('.datepicker').datetimepicker({
        timepicker: false,
        format: 'd-m-Y',
        dayOfWeekStart: 1,
        scrollInput: false,
        minDate: 0

    });
}

export  {dateselector};