
import $ from 'jquery';
import slick from 'slick-carousel';


function mainSlider (){

    $('.js-slider').each(function() {
        $( this ).slick({
            dots: false,
            prevArrow: $('.c-slider__arrows-inside .prev-slide', this ),
            nextArrow: $('.c-slider__arrows-inside .next-slide', this ),

        });
    })

}
function reviewSlider (){

    $('.js-testimonials').slick({
        dots: true,
        // autoplay: true,
        autoplaySpeed: 4000,
        /*
        prevArrow:'<i class="c-slider__chevron c-slider__chevron--left fal fa-chevron-left"></i>',
        nextArrow:'<i class="c-slider__chevron c-slider__chevron--right fal fa-chevron-right"></i>',*/
        //appendDots:$('js-reviews').find('.js-customdots'),

    });

}

function serviceSlider (){

    $('.js-services-for').slick({
        dots: false,
        // autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
        asNavFor: '.js-services-nav',
        prevArrow:'<i class="c-slider__chevron c-slider__chevron--left fal fa-chevron-left"></i>',
        nextArrow:'<i class="c-slider__chevron c-slider__chevron--right fal fa-chevron-right"></i>',
    });
    $('.js-services-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.js-services-for',
        dots: false,
        centerMode: true,
        focusOnSelect: true,
        prevArrow:'<i class="c-slider__arrow c-slider__arrow--outerprev fal fa-chevron-left"></i>',
        nextArrow:'<i class="c-slider__arrow c-slider__arrow--outernext fal fa-chevron-right"></i>',
    });

}

function servicesSlider (){

    $('.js-services').slick(getSliderSettings());

    $('.js-selector a').on('click',function(e){
        e.preventDefault();
        var targetnav=$(this).attr('data-target');
        $('.js-tab').removeClass('active');
        $(this).addClass('active');
        $('.c-services__tabs-tab').removeClass('active');
        $(".js-scontent").find("[data-tab='" + targetnav + "']").addClass('active');
        $('.js-services').slick('unslick');
        $('.js-services').slick(getSliderSettings());
    })
}
function aboutSlider (){

    $('.js-about').slick({
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
        infinite:false,
        prevArrow:'<i class="c-slider__arrow c-slider__arrow--innerprev fal fa-chevron-left"></i>',
        nextArrow:'<i class="c-slider__arrow c-slider__arrow--innernext fal fa-chevron-right"></i>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]

    });


}

function exhibitionsSlider (){

    $('.js-exhibitions-slider').slick({
        dots: false,
        slidesToShow: 1,
        slidesToScroll:1,
        slidesPerRow:3,
        autoplaySpeed: 4000,
        infinite:true,
        vertical: true,
        verticalSwiping:true,
        prevArrow: $('.c-exhibitions__carousel .prev-slide'),
        nextArrow: $('.c-exhibitions__carousel .next-slide'),
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                   slidesToShow: 2,
                   vertical: false,
                   verticalSwiping:false,
                   slidesPerRow:1,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    vertical: false,
                    verticalSwiping:false,
                    slidesPerRow:1,
                }
            },

        ]

    });


}
function artistSlider (){

    $('.js-artist-slider').slick({
        dots: true,
        slidesToShow: 1,
        slidesToScroll:1,
        slidesPerRow:2,
        autoplaySpeed: 4000,
        infinite:true,
        vertical: true,
        verticalSwiping:true,
        prevArrow: $('.c-exhibitions__carousel .prev-slide'),
        nextArrow: $('.c-exhibitions__carousel .next-slide'),
        appendDots: $('.c-exhibitions__carousel .dots-container'),
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    vertical: false,
                    verticalSwiping:false,
                    slidesPerRow:1,
                }
            },

        ]

    });


}

function eventsSlider (){

    $('.js-events-slider').slick({
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow:4,
        autoplaySpeed: 4000,
        infinite:false,
        vertical: true,
        verticalSwiping:true,
        prevArrow: $('.c-events__carousel .prev-slide'),
        nextArrow: $('.c-events__carousel .next-slide'),
        appendDots: $('.c-events__carousel .dots-container'),
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    vertical: false,
                    verticalSwiping:false,
                    slidesPerRow:1,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    vertical: false,
                    verticalSwiping:false,
                    slidesPerRow:1,
                }
            },

        ]

    });



}

function eventSlider (){
    $('.js-exhibitions-gallery').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = (currentSlide ? currentSlide : 0) + 1;
        $('.js-slider-count').html('<b>'+ i + '</b>' + '/' + slick.slideCount);
    });
    $('.js-exhibitions-gallery').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
        infinite:false,
        prevArrow: $('.c-exhibitions__gallery .prev-slide'),
        nextArrow: $('.c-exhibitions__gallery .next-slide')

    });


}

function modalSlider (){
    $('.js-modal').each(function() {
        var current = $(this);
        $('.js-modal-slider', this ).on('init reInit afterChange', function(event, slick, currentSlide, nextSlide,current){
             var i = (currentSlide ? currentSlide : 0) + 1;

             /*console.log($(this).parents('.js-modal').find('.js-slider-count'));*/
            $(this).parents('.js-modal').find('.js-slider-count').html('<b>'+ i + '</b>' + '/' + slick.slideCount);
         });
        $('.js-modal-slider', this ).slick({
             slidesToShow: 1,
             slidesToScroll: 1,
             autoplaySpeed: 4000,
             infinite:true,
             prevArrow: $('.prev-slide', this ),
             nextArrow: $('.next-slide', this ),
             asNavFor: $('.slider-nav', this ),
         });

        $('.slider-nav', this).slick({
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: $('.js-modal-slider', this ),
        });
    })

}

function getYearSelectorSettings(){
    return {
        dots: false,
        slidesToShow: 20,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
        infinite:false,
        vertical: true,
        verticalSwiping:true,
        prevArrow:'<i class="fal fa-chevron-up"></i>',
        nextArrow:'<i class="fal fa-chevron-down"></i>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 10,
                    vertical: false,
                    verticalSwiping:false,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 6,
                    vertical: false,
                    verticalSwiping:false,
                }
            },

        ]
    }
}

function yearsSelector (){

    $('.js-slider-years').slick(getYearSelectorSettings());
}


function getSliderSettings(){
    return {
        dots: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
        prevArrow:'<i class="c-slider__arrow c-slider__arrow--outerprev fal fa-chevron-left"></i>',
        nextArrow:'<i class="c-slider__arrow c-slider__arrow--outernext fal fa-chevron-right"></i>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    }
}

export  {mainSlider,servicesSlider,reviewSlider,serviceSlider,aboutSlider,exhibitionsSlider,eventsSlider,yearsSelector,getYearSelectorSettings,eventSlider,artistSlider,modalSlider};