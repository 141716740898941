
var $ = require("jquery");
window.jQuery = $;
require("@fancyapps/fancybox");


function carousel (){

    $("[data-fancybox]").fancybox({
        thumbs : {
            autoStart : true
        },
        buttons: [
            "zoom",
            "slideShow",
            "fullScreen",
            "download",
            "thumbs",
            "close"
        ],
        caption : function( instance, item ) {
            var caption = $(this).data('caption') || '';
            var title = $(this).data('title') || '';
            return ( title.length ? title + '<br />' : '' );
        },
    });

}

export  {carousel};